import * as React from "react";
import {
	cn,
	DataObserveKey,
	FocusableComponent,
	Icon,
	IconTrend,
	SrOnly,
	VisualComponent,
} from "@siteimprove/fancylib";
import { FormattedNumber } from "../../text/formatted-number/formatted-number";
import { useLabTranslations, TranslationKeys } from "../../../translations/translations";
import { useDesignToken } from "../../context/theme/theme";
import * as scss from "./trend-formatter.scss";

export type BaseTrendFormatterProps = {
	/** The trend value */
	value: number | string;
	/** The arrow orientation */
	direction: "up" | "down" | "neutral";
	/** The arrow color */
	color?: string;
	/** Whether the arrow comes before or after the number */
	alignment?: "left" | "right";
	/** Label of the trend */
	"aria-label"?: string;
	/** ID of an an element that labels this trend */
	"aria-labelledby"?: string;
	/** ID of an an element that describes the trend content */
	"aria-describedby"?: string;
} & DataObserveKey &
	VisualComponent &
	FocusableComponent;

export function BaseTrendFormatter(props: BaseTrendFormatterProps): JSX.Element {
	const {
		value,
		alignment,
		direction,
		className,
		onBlur,
		onFocus,
		onKeyDown,
		onMouseDown,
		onMouseEnter,
		onMouseLeave,
	} = props;
	const i18n = useLabTranslations();
	const { ColorGray } = useDesignToken();
	const color = props.color || ColorGray;

	const renderArrow = () => {
		return (
			<>
				<Icon fill={color} size="responsive">
					<IconTrend className={cn(scss.arrow, scss[direction])} />
				</Icon>
			</>
		);
	};

	const renderValue = (): JSX.Element => {
		if (typeof value == "number") {
			return <FormattedNumber number={value} format="number" digits={2} />;
		}
		return <span>{value}</span>;
	};

	const renderA11yContent = () => {
		const translationKeys: Record<BaseTrendFormatterProps["direction"], TranslationKeys> = {
			down: "directionDown",
			neutral: "directionNeutral",
			up: "directionUp",
		};
		const targetKey: TranslationKeys = translationKeys[direction];
		return <SrOnly isInline>{i18n[targetKey]}:&nbsp;</SrOnly>;
	};

	return (
		<div
			aria-describedby={props["aria-describedby"]}
			aria-label={props["aria-label"]}
			aria-labelledby={props["aria-labelledby"]}
			data-component="trend-formatting"
			data-observe-key={props["data-observe-key"]}
			onMouseDown={onMouseDown}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onKeyDown={onKeyDown}
			onBlur={onBlur}
			onFocus={onFocus}
			style={props.style}
			className={cn(scss.trendFormatter, className)}
		>
			{renderA11yContent()}
			{alignment !== "right" && renderArrow()}
			{renderValue()}
			{alignment === "right" && renderArrow()}
		</div>
	);
}

export type TrendFormatterProps = Omit<BaseTrendFormatterProps, "value" | "direction"> & {
	/** The trend value */
	value: number;
	/** The reference value */
	referenceValue: number;
	variant?: "green-positive" | "red-positive" | "neutral";
};

export function TrendFormatter(props: TrendFormatterProps): JSX.Element {
	const { value, referenceValue, variant = "green-positive", ...rest } = props;
	const { ColorStatusSubtleDark, ColorStatusPositiveDark, ColorStatusNegativeDark } =
		useDesignToken();

	let color: string | undefined;
	const diff = value - referenceValue;
	const direction: BaseTrendFormatterProps["direction"] =
		diff > 0 ? "up" : diff < 0 ? "down" : "neutral";

	if (variant === "neutral" || diff === 0) color = ColorStatusSubtleDark;
	else if (variant === "green-positive")
		color = diff > 0 ? ColorStatusPositiveDark : ColorStatusNegativeDark;
	else if (variant === "red-positive")
		color = diff > 0 ? ColorStatusNegativeDark : ColorStatusPositiveDark;

	return <BaseTrendFormatter value={value} color={color} direction={direction} {...rest} />;
}
